<template>
  <PageTemplate>
		<Grid>
			<GridContainer size="12">
				<Heading size="3">Typography</Heading>
			</GridContainer>
			<GridContainer size="12 6@md">
				<Card>
					<CardSection>
						<Heading size="5">Font Family</Heading>
					</CardSection>
					<CardSection>
						<Stack width="100%" direction="column" align="left" space="1">
							<Stage type="AR">
								<Heading size="1" margin="none">'Poppins', sans-serif</Heading>
							</Stage>
							<Stage type="AR">
								<Paragraph margin="none">'Lora', sans-serif</Paragraph>
							</Stage>
							<Stage type="DL">
								<Heading size="2" margin="none">'Lora', sans-serif</Heading>
							</Stage>
							<Stage type="DL">
								<Paragraph margin="none">'Open Sans', sans-serif</Paragraph>
							</Stage>
							<Stage type="ED">
								<Heading size="2" margin="none">'Montserrat', sans-serif</Heading>
							</Stage>
							<Stage type="ED">
								<Paragraph margin="none">'Montserrat', sans-serif</Paragraph>
							</Stage>
							<Stage type="HV">
								<Heading size="2" margin="none">'Montserrat', sans-serif</Heading>
							</Stage>
							<Stage type="HV">
								<Paragraph margin="none">'Montserrat', sans-serif</Paragraph>
							</Stage>
							<Stage type="SD">
								<Heading size="1" margin="none">'Playfair Display', serif</Heading>
							</Stage>
							<Stage type="SD">
								<Paragraph margin="none">'Josefin Sans', sans-serif</Paragraph>
							</Stage>
							<Stage type="WM">
								<Heading size="3" margin="none">'Montserrat', sans-serif</Heading>
							</Stage>
							<Stage type="WM">
								<Paragraph margin="none">'Open Sans', sans-serif</Paragraph>
							</Stage>
							<Stage type="DS">
								<Heading size="1" margin="none">'Montserrat', sans-serif</Heading>
							</Stage>
							<Stage type="DS">
								<Paragraph margin="none">'Montserrat', sans-serif</Paragraph>
							</Stage>
						</Stack>
					</CardSection>
				</Card>
			</GridContainer>
			<GridContainer size="12 6@md">
			</GridContainer>				
			<GridContainer size="6">
				<Card>
					<CardSection>
						<Heading size="5">Headings</Heading>
					</CardSection>
					<CardSection>
						<Stack width="100%" direction="column" align="left" space="1">
							<Stage><Heading size="1" margin="none">Heading 1</Heading></Stage>
							<Stage><Heading size="2" margin="none">Heading 2</Heading></Stage>
							<Stage><Heading size="3" margin="none">Heading 3</Heading></Stage>
							<Stage><Heading size="4" margin="none">Heading 4</Heading></Stage>
							<Theme ar ds ed hv sd wm>
								<Stage><Heading size="5" margin="none">Heading 5</Heading></Stage>
							</Theme>
							<Theme ar ds ed wm>
								<Stage><Heading size="6" margin="none">Heading 6</Heading></Stage>
							</Theme>
						</Stack>
					</CardSection>
				</Card>
			</GridContainer>
			<GridContainer size="6">
				<Card>
					<CardSection>
						<Heading size="5">Paragraphs</Heading>
					</CardSection>
					<CardSection>
						<Stack width="100%" direction="column" align="left" space="1">
							<Stage><Paragraph margin="none">Paragraph (Default)</Paragraph></Stage>
							<Theme wm>
								<Stage><Paragraph margin="none" feature>Paragraph Feature</Paragraph></Stage>
							</Theme>
							<Theme ds sd>
								<Stage><Paragraph margin="none" bold>Paragraph Bold</Paragraph></Stage>
							</Theme>
							<Theme ds ar dl ed hv sd wm>
								<Stage><Paragraph margin="none" micro>Paragraph Micro</Paragraph></Stage>
							</Theme>
						</Stack>
					</CardSection>
				</Card>
			</GridContainer>
			<GridContainer size="12">
				<Card>
					<CardSection>
						<Heading size="5">Quotes</Heading>
					</CardSection>
					<CardSection>
						<Stack width="100%" direction="column" align="left">
							<Stage>
								<Quote>For the happiest life, days should be rigorously planned, nights left open to be discovered</Quote>
								<Paragraph>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse non sem dolor. Pellentesque vel tincidunt purus. Maecenas ultricies, urna dictum finibus luctus, tortor ligula blandit libero, ut accumsan nibh tellus et lacus. In hac habitasse platea dictumst. Praesent convallis sapien purus, nec consectetur est mollis ac. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse non sem dolor. Pellentesque vel tincidunt purus. Maecenas ultricies, urna dictum finibus luctus, tortor ligula blandit libero, ut accumsan nibh tellus et lacus. In hac habitasse platea dictumst. Praesent convallis sapien purus, nec consectetur est mollis ac.</Paragraph>
								<Quote align="left">Do not mind anything that anyone tells you about anyone else</Quote>
								<Paragraph>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse non sem dolor. Pellentesque vel tincidunt purus. Maecenas ultricies, urna dictum finibus luctus, tortor ligula blandit libero, ut accumsan nibh tellus et lacus. In hac habitasse platea dictumst. Praesent convallis sapien purus, nec consectetur est mollis ac.</Paragraph>
								<Paragraph>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse non sem dolor. Pellentesque vel tincidunt purus. Maecenas ultricies, urna dictum finibus luctus, tortor ligula blandit libero, ut accumsan nibh tellus et lacus. In hac habitasse platea dictumst. Praesent convallis sapien purus, nec consectetur est mollis ac.</Paragraph>
								<Quote align="right">Do not mind anything that anyone tells you about anyone else</Quote>
								<Paragraph>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse non sem dolor. Pellentesque vel tincidunt purus. Maecenas ultricies, urna dictum finibus luctus, tortor ligula blandit libero, ut accumsan nibh tellus et lacus. In hac habitasse platea dictumst. Praesent convallis sapien purus, nec consectetur est mollis ac.</Paragraph>
							</Stage>
						</Stack>
					</CardSection>
				</Card>
			</GridContainer>
			<GridContainer size="12">
				<Card>
					<CardSection>
						<Stack direction="column" space="2" align="left">
							<Heading size="5">Heading Properties</Heading>
						</Stack>
					</CardSection>
					<CardSection>
						<Table>
							<TableRow head>
								<TableCell>
									<Paragraph margin="none" bold>Property</Paragraph>
								</TableCell>
								<TableCell>
									<Paragraph margin="none" bold>Type</Paragraph>
								</TableCell>
								<TableCell>
									<Paragraph margin="none" bold>Options</Paragraph>
								</TableCell>
								<TableCell>
									<Paragraph margin="none" bold>Explanation</Paragraph>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Property</Paragraph></Stack>
									<Paragraph margin="none">size</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Type</Paragraph></Stack>
									<Paragraph margin="none">String</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Options</Paragraph></Stack>
									<Paragraph margin="none">1-6</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Explanation</Paragraph></Stack>
									<Paragraph margin="none">Defines the size of the heading.</Paragraph>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Property</Paragraph></Stack>
									<Paragraph margin="none">align</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Type</Paragraph></Stack>
									<Paragraph margin="none">String</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Options</Paragraph></Stack>
									<Paragraph margin="none">center</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Explanation</Paragraph></Stack>
									<Paragraph margin="none">Alignment of the heading in the container it is in.</Paragraph>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Property</Paragraph></Stack>
									<Paragraph margin="none">color</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Type</Paragraph></Stack>
									<Paragraph margin="none">String</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Options</Paragraph></Stack>
									<Paragraph margin="none">
										primary<br/>
										secondary<br/>
										tertiary
									</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Explanation</Paragraph></Stack>
									<Paragraph margin="none">The base color that applies to the heading.</Paragraph>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Property</Paragraph></Stack>
									<Paragraph margin="none">uppercase</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Type</Paragraph></Stack>
									<Paragraph margin="none">Boolean</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Options</Paragraph></Stack>
									<Paragraph margin="none">-</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Explanation</Paragraph></Stack>
									<Paragraph margin="none">The text displayed as uppercase.</Paragraph>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Property</Paragraph></Stack>
									<Paragraph margin="none">whiteout</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Type</Paragraph></Stack>
									<Paragraph margin="none">Boolean</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Options</Paragraph></Stack>
									<Paragraph margin="none">-</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Explanation</Paragraph></Stack>
									<Paragraph margin="none">Turns the heading white. Use over dark backgrounds.</Paragraph>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Property</Paragraph></Stack>
									<Paragraph margin="none">margin</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Type</Paragraph></Stack>
									<Paragraph margin="none">String</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Options</Paragraph></Stack>
									<Paragraph margin="none">'none'</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Explanation</Paragraph></Stack>
									<Paragraph margin="none">Removes all default margin bottoms from the heading</Paragraph>
								</TableCell>
							</TableRow>
						</Table>
					</CardSection>
				</Card>
			</GridContainer>
			<GridContainer size="12">
				<Card>
					<CardSection>
						<Stack direction="column" space="2" align="left">
							<Heading size="5">Paragraph Properties</Heading>
						</Stack>
					</CardSection>
					<CardSection>
						<Table>
							<TableRow head>
								<TableCell>
									<Paragraph margin="none" bold>Property</Paragraph>
								</TableCell>
								<TableCell>
									<Paragraph margin="none" bold>Type</Paragraph>
								</TableCell>
								<TableCell>
									<Paragraph margin="none" bold>Options</Paragraph>
								</TableCell>
								<TableCell>
									<Paragraph margin="none" bold>Explanation</Paragraph>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Property</Paragraph></Stack>
									<Paragraph margin="none">bold</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Type</Paragraph></Stack>
									<Paragraph margin="none">Boolean</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Options</Paragraph></Stack>
									<Paragraph margin="none">-</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Explanation</Paragraph></Stack>
									<Paragraph margin="none">Determines whether to apply the bold styles.</Paragraph>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Property</Paragraph></Stack>
									<Paragraph margin="none">micro</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Type</Paragraph></Stack>
									<Paragraph margin="none">Boolean</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Options</Paragraph></Stack>
									<Paragraph margin="none">-</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Explanation</Paragraph></Stack>
									<Paragraph margin="none">Determines whether to apply the micro styles.</Paragraph>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Property</Paragraph></Stack>
									<Paragraph margin="none">feature</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Type</Paragraph></Stack>
									<Paragraph margin="none">Boolean</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Options</Paragraph></Stack>
									<Paragraph margin="none">-</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Explanation</Paragraph></Stack>
									<Paragraph margin="none">Determines whether to apply the feature styles.</Paragraph>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Property</Paragraph></Stack>
									<Paragraph margin="none">align</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Type</Paragraph></Stack>
									<Paragraph margin="none">String</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Options</Paragraph></Stack>
									<Paragraph margin="none">
										center<br/>
										right
										</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Explanation</Paragraph></Stack>
									<Paragraph margin="none">Alignment of the heading in the container it is in.</Paragraph>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Property</Paragraph></Stack>
									<Paragraph margin="none">whiteout</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Type</Paragraph></Stack>
									<Paragraph margin="none">Boolean</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Options</Paragraph></Stack>
									<Paragraph margin="none">-</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Explanation</Paragraph></Stack>
									<Paragraph margin="none">Turns the paragraph white. Use over dark backgrounds.</Paragraph>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Property</Paragraph></Stack>
									<Paragraph margin="none">margin</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Type</Paragraph></Stack>
									<Paragraph margin="none">String</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Options</Paragraph></Stack>
									<Paragraph margin="none">'none'</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Explanation</Paragraph></Stack>
									<Paragraph margin="none">Removes all default margin bottoms from the paragraph.</Paragraph>
								</TableCell>
							</TableRow>
						</Table>
					</CardSection>
				</Card>
			</GridContainer>
			<GridContainer size="12">
				<Card>
					<CardSection>
						<Stack direction="column" space="2" align="left">
							<Heading size="5">Quote Properties</Heading>
						</Stack>
					</CardSection>
					<CardSection>
						<Table>
							<TableRow head>
								<TableCell>
									<Paragraph margin="none" bold>Property</Paragraph>
								</TableCell>
								<TableCell>
									<Paragraph margin="none" bold>Type</Paragraph>
								</TableCell>
								<TableCell>
									<Paragraph margin="none" bold>Options</Paragraph>
								</TableCell>
								<TableCell>
									<Paragraph margin="none" bold>Explanation</Paragraph>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Property</Paragraph></Stack>
									<Paragraph margin="none">align</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Type</Paragraph></Stack>
									<Paragraph margin="none">String</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Options</Paragraph></Stack>
									<Paragraph margin="none">
										left<br/>
										right
										</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Explanation</Paragraph></Stack>
									<Paragraph margin="none">Alignment of the quote. A paragraph will wrap around the quote.</Paragraph>
								</TableCell>
							</TableRow>
						</Table>
					</CardSection>
				</Card>
			</GridContainer>
		</Grid>
	</PageTemplate>
</template>

<script>
import PageTemplate from '@/views/designsystem/template/Page'; 
export default {
  components: {
		PageTemplate
  },
  data() {
    return {
      theme: ''
		}
  },
  mounted() {
		setTimeout(() =>{ 
			this.stageTheme(this.theme);
		}, 30);
  },
}
</script>

<style lang="scss" scoped>
</style>
